<template>
  <div class="selectOptions">
    <van-field
      v-model="formData.name" 
      inputAlign="left" 
      readonly 
      label='' 
      :placeholder="$t('请选择')" 
      @click="showPopup">
      <template #right-icon>
        <template>
          <van-icon v-if="formData.name" name="close" @click.stop="eliminate" />
          <van-icon v-else name="arrow" />
        </template>
      </template>
    </van-field>
    <van-popup v-model="popupShow" position="bottom">
      <div class="popupBox">
        <div class="title">
          <span>{{ title }}</span>
          <span v-if="itemObj.multiple" style="color: #B9921A;" @click="confirm">{{ $t('确定') }}</span>
        </div>
        <div class="infoContent">
          <div v-for="(item, index) in dataList" :key="index" :class="['infoItem']" @click="onSelectItem(item)">
            <span>{{ item.name }}</span>
            <van-icon v-if="isActive(item)" name="success" color="#B9921A" size="18px"/>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { i18n } from '@/main'
export default {
  props: {
    formData:{
      type: Object,
      default:()=>({})
    },
    itemObj: {
      type: Object,
      default:()=>({})
    },
    dataList: {
      type: Array,
      default: ()=>([])
    }
  },
  data() {
    return {
      popupShow: false,
      // 选中列表
      parentCodeSelected: [],
      childCodeSelected: [],
      dealerProvinceCodeSelected: [],
      dealerCityCodeSelected: [],
      dealerIdsSelected: [],
    }
  },
  computed: {
    title() {
      const { field, dealerType } = this.itemObj
      return {
        parentCode: this.$t('请选择大区'),
        childCode: this.$t('请选择城市区域'),
        dealerProvinceCode: this.$t('请选择省份'),
        dealerCityCode: this.$t('请选择城市'),
        dealerIds: this.$t(dealerType == 2 ? '请选择交付门店' : dealerType == 1 ? '请选择销售门店' : '请选择门店'),
      }[field]
    },
    isActive() {
      return (val) => {
        if (!val) return false
        const { formData } = this
        const { field } = this.itemObj
        // return this[`${field}Selected`].length > 0 ? this[`${field}Selected`].includes(val.code) : formData.selected.includes(val.code)
        return this[`${field}Selected`].length > 0 ? this[`${field}Selected`].includes(val.code) : false
      }
    },
  },
  watch: {
    formData:{
      handler(val) {
        this.formDataNew = JSON.parse(JSON.stringify(val))
        const { field } = this.itemObj
        this[`${field}Selected`] = this.formDataNew.selected
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    showPopup() {
      this.popupShow = true
    },
    // 选择某一个
    onSelectItem(item) {
      const { multiple, field } = this.itemObj
      if (['parentCode', 'dealerProvinceCode'].includes(field)) {
        this.formDataNew.children = item.children
      }
      if (multiple) {
        const findIndex = this.formDataNew.selected.indexOf(item.code)
        if (findIndex !== -1) {
          this.formDataNew.selected.splice(findIndex, 1)
        } else {
          this.formDataNew.selected.push(item.code)
        }
        this[`${field}Selected`] = this.formDataNew.selected
        this.formDataNew[field] = this.formDataNew.selected.join(',')
        this.formDataNew.value = this.formDataNew.selected
        const selectItem = this.dataList.filter(({ code }) => { return this.formDataNew.selected.includes(code) })
        this.formDataNew.name = selectItem.map(({ name }) => { return name }).join(',')
      } else {
        this.formDataNew.selected = []
        this[`${field}Selected`] = []
        this.formDataNew[field] = ''
        this.formDataNew.value = ''
        this.formDataNew[field] = item.code
        this.formDataNew.value = item.code
        this[`${field}Selected`].push(item.code)
        this.formDataNew.selected.push(item.code)
        this.formDataNew.name = item.name
        this.popupShow = false
        this.$emit('change', this.formDataNew)
      }
    },
    // 多选的确定
    confirm() {
      if (this.formDataNew.selected.length === 0) {
        this.$toast(this.$t('请至少选择一项'))
        return false
      }
      this.popupShow = false
      this.$emit('change', this.formDataNew)
    },
    // 清除
    eliminate() {
      const { field, multiple } = this.itemObj
      const { formDataNew } = this
      const obj = JSON.parse(JSON.stringify(formDataNew))
      this[`${field}Selected`] = []
      obj.children = []
      obj.name = ''
      obj[field] = ''
      obj.selected = []
      if (multiple) {
        obj.value = []
      } else {
        obj.value = ''
      }
      this.$emit('change', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.selectOptions{
  /deep/ .popupBox{
    display: flex;
    flex-direction: column;
    padding: 16px;
    .title{
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between
    }
    .infoContent{
      display: flex;
      flex-direction: column;
      max-height: 65vh;
      overflow-y: scroll;
      .infoItem{
        padding: 10px 0;
        display: flex;
        justify-content: space-between;

      }
    }
  }
}
</style>