<template>
<div>
  <van-popup
    v-model="visibleShow"
    get-container="body"
    position="right"
    :style="{ minWidth: '335px', width: '80%', height: '100%' }"
    :close-on-popstate="true"
    @close="onClose"
  >
    <div class="filter-panel">
      <div class="content">
        <div v-for="item in options" v-show="!item.hidden" :key="item.id" :class="['item', item.type === 'selectOptions' ? 'selectOptions_item' : '']">
          <div v-if="item.label" class="label">
            <span>{{ item.label }}</span>
            <span v-if="item.type === 'dateOptions'&&customDateTime" style="font-size: 12px;">
              {{ customDateTime }}
            </span>
            <span v-if="item.type === 'dataRange'" class="time-options">
              <van-tag v-for="itemItem in (item.timeOptions || dateOption)" :key="itemItem.value" color="#F2F2F2" text-color="#B2B2B2" class="option" @click="selectDateOption(itemItem,item)">
                {{ itemItem.text }}
              </van-tag>
            </span>
          </div>
          <!-- 日期范围选择 -->
          <template v-if="item.type === 'dataRange'">
            <div class="date-range">
              <div class="btn-select" @click="onSelectDate(item.start, 'start', item.end.field, item.dateType)">
                <div
                  v-if="formData[item.start.field]"
                  class="value"
                >
                  {{ formData[item.start.field] | timeFormat(item.dateType !== 'datetime' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm') }}
                </div>
                <div v-else class="value placeholder">{{$t('开始时间')}}</div>
                <img class="icon" src="@/images/icon_dropdown_date.png">
              </div>
              <div class="btn-select" @click="onSelectDate(item.end, 'end', item.start.field, item.dateType)">
                <div
                  v-if="formData[item.end.field]"
                  class="value"
                >
                  {{ formData[item.end.field] | timeFormat(item.dateType !== 'datetime' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm') }}
                </div>
                <div v-else class="value placeholder">{{$t('结束时间')}}</div>
                <img class="icon" src="@/images/icon_dropdown_date.png">
              </div>
            </div>
          </template>
          <!-- 日期范围快捷选项 -->
          <template v-if="item.type === 'dateOptions'">
            <div class="options">
              <div
                v-for="it in item.quickOptions"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].id===it.id}"
                @click="onSelectDateRange(item.field,it)"
              >
                {{ it.label }}
              </div>
            </div>
            <!-- 自定义 -->
              <CommonDatePicker v-if="isOpenCustom"
                @confirm="handleDateTimeConfirm">
              </CommonDatePicker>
          </template>
          <!-- 普通选择 -->
          <template v-if="item.type === 'options'">
            <!--单选-->
            <div v-if="!item.multiple" class="options">
              <div
                v-for="it in item.options"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].id===it.id}"
                @click="onSelectedOption(item.field, it, item.multiple,item)"
              >{{ it.label }}</div>
            </div>
            <!--车系多选-->
            <div v-if="item.multiple && (item.field === 'seriesCodes' || item.field === 'seriesCodeList') && seriesList.length > 0" class="options">
              <div
                v-for="it in seriesList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
              <div v-if="allSeriesList.length>8 && isMobile" class="option" @click="selectOtherOption(item)">...</div>
            </div>
            <!--车型多选-->
            <div v-if="item.multiple && (item.field === 'modelCodes' || item.field === 'modelCodeList' || item.field === 'vehicles' ) && modelList.length > 0" class="options">
              <div
                v-for="it in modelList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!--车系单选-->
            <div v-if="!item.multiple && (item.field === 'seriesCodes' || item.field === 'seriesCodeList') && seriesList.length > 0" class="options">
              <div
                v-for="it in seriesList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].id===it.id}"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
              <div v-if="allSeriesList.length>8 && isMobile" class="option" @click="selectOtherOption(item)">...</div>
            </div>
            <!--车型单选-->
            <div v-if="!item.multiple && (item.field === 'modelCodes' || item.field === 'modelCodeList') && modelList.length > 0" class="options">
              <div
                v-for="it in modelList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].id===it.id}"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!--年款单选-->
            <div v-if="!item.multiple && (item.field === 'styleCodes' || item.field === 'styleCodeList') && styleList.length > 0" class="options">
              <div
                v-for="it in styleList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].id===it.id}"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!--渠道二级-->
            <div v-if="item.field === 'channelTwoId'" class="options">
              <div
                v-for="it in channelTwoList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!-- 渠道三级 -->
            <div v-if="item.field === 'channelThreeId'" class="options">
              <div
                v-for="it in channelThreeList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!--渠道四级-->
            <div v-if="item.field === 'channelFourId'" class="options">
              <div
                v-for="it in channelFourList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!-- 渠道五级 -->
            <div v-if="item.field === 'channelFiveId'" class="options">
              <div
                v-for="it in channelFiveList"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && !!formData[item.field].find(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple)"
              >{{ it.label }}</div>
            </div>
            <!--多选-->
            <div v-if="item.multiple" class="options">
              <div
                v-for="it in item.options"
                :key="it.id"
                class="option"
                :class="{
                  active: formData[item.field] && formData[item.field].some && formData[item.field].some(i => i.id === it.id) }"
                @click="onSelectedOption(item.field, it, item.multiple,item)"
              >{{ it.label }}</div>
              <div
                v-if="item.field === 'channelTwoId' && allUserSourceList.length>8 && isMobile"
                class="option"
                @click="selectOtherOption(item)">...</div>
            </div>
          </template>

          <!-- 输入框，一组 -->
          <template v-if="item.type === 'inputNums'">
            <div class="input-nums">
              <div class="input-box">
                <input
                  v-model="formData[item.options[0].field]"
                  :type="item.options[0].type"
                  :min="item.options[0].min"
                  :placeholder="item.options[0].placeholder || $t('请输入')"
                  @change="handleInputNumsChange(0, item.options)"
                />
              </div>
              <!-- 分隔符 -->
              <div class="separator">~</div>
              <div class="input-box">
                <input
                  v-model="formData[item.options[1].field]"
                  :type="item.options[1].type"
                  :min="item.options[1].min"
                  :placeholder="item.options[1].placeholder || $t('请输入')"
                  @change="handleInputNumsChange(1, item.options)"
                />
              </div>
            </div>
          </template>

          <!-- 快捷标签输入框，搭配联级选择器使用 -->
          <CommonSelectTagsInput
            v-if="item.tagsInput"
            v-bind="item"
            :tags="item.checkedObjectArray"
            :tagsNumber="1"
            style="margin-bottom: 6px;"
            @delete="tagValue => handleTagsInputDelete(tagValue, item, index)"
            @search="value => handleTagsInputSearch(value, item, index)"
          >
          </CommonSelectTagsInput>

          <!-- 渠道，级联选择器，新版 -->
          <BusinessCascaderChannels
            v-if="item.type === 'channels'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            @change="(values) => handleChannelsCascaderChange(values, item)"
          >
          </BusinessCascaderChannels>

          <!-- 车系车型，级联选择器，新版 -->
          <BusinessCascaderBrandSeriesModels
            v-if="item.type === 'cars'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            @change="(values) => handleBrandCarsCascaderChange(values, item)"
          >
          </BusinessCascaderBrandSeriesModels>
          <!-- 公共级联选择 -->
          <BusinessCommonCascader
            v-if="item.type === 'commonCascader'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            @change="(values) => handleBrandCarsCascaderChange(values, item)"
          >
          </BusinessCommonCascader>
          <!-- 省市区选择 -->
          <AddressSystem 
            v-if="item.type === 'address'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            :formData="addressObj"
            @change="(val)=>onAddressChange(val,item)">
          </AddressSystem>
          <!-- 大区/城市区域/省份/城市/门店选择  -->
          <template v-if="item.type === 'selectOptions'">
            <SelectOptions
              v-if="item.field === 'parentCode'"
              :ref="`${item.type}_${item.field}`"
              :formData="parentCodeObj"
              :dataList="item.options"
              :itemObj="item"
              @change="(values) => onSelectOptionsChange(values, item)">
            </SelectOptions>
            <SelectOptions
              v-if="item.field === 'childCode'"
              :ref="`${item.type}_${item.field}`"
              :formData="childCodeObj"
              :dataList="childList"
              :itemObj="item"
              @change="(values) => onSelectOptionsChange(values, item)">
            </SelectOptions>
            <SelectOptions
              v-if="item.field === 'dealerProvinceCode'"
              :ref="`${item.type}_${item.field}`"
              :formData="dealerProvinceCodeObj"
              :dataList="item.options"
              :itemObj="item"
              @change="(values) => onSelectOptionsChange(values, item)">
            </SelectOptions>
            <SelectOptions
              v-if="item.field === 'dealerCityCode'"
              :ref="`${item.type}_${item.field}`"
              :formData="dealerCityCodeObj"
              :dataList="cityList"
              :itemObj="item"
              @change="(values) => onSelectOptionsChange(values, item)">
            </SelectOptions>
            <SelectOptions
              v-if="item.field === 'dealerIds'"
              :ref="`${item.type}_${item.field}`"
              :formData="dealerIdsObj"
              :dataList="item.options"
              :itemObj="item"
              @change="(values) => onSelectOptionsChange(values, item)">
            </SelectOptions>
          </template>
        </div>
      </div>
      <div v-if="!isOpenCustom" class="actions">
        <button class="btn-common plain" @click="reset">{{$t('重置')}}</button>
        <button class="btn-common" @click="submit">{{$t('确认')}}</button>
      </div>
    </div>
  </van-popup>
  <van-dialog v-model="dialogShow" confirmButtonColor="#2F7DCD">
    <div class="filter-panel">
      <div class="content">
        <div
          v-for="item in dialogOptions"
          :key="item.id"
          class="item">
          <div class="options">
            <div
              v-for="optionItem in dialogList"
              :key="optionItem.id"
              class="option"
              :class="{
                active: isArrayMark ? (formData[item.field] && !!formData[item.field].find(i => i.id === optionItem.id)) : (formData[item.field] && formData[item.field].id===optionItem.id)
              }"
              @click="onSelectedOption(item.field, optionItem, item.multiple)"
            >
              {{optionItem.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-dialog>
</div>
</template>

<script>
import selectDate from '@/components/date-picker'
import selectCar from '@/components/select-car'
import baseDataServices from '@/services/baseDataServices'
import salesAssistantService from '@/services/salesAssistant'
import clueServices from '@/services/clueServices'
import { v4 as uuidv4 } from 'uuid'
import selectLocation from '@/components/location-picker'
import moment from 'moment'
import { dateFormat } from '@/utils'
import { isArray, cloneDeep } from 'lodash'

// Components
import CommonSelectTagsInput from '@/components/v2/system/CommonSelectTagsInput/index.vue'
import CommonDatePicker from '@/components/v2/system/CommonDatePicker/index.vue'

// Components Business
import BusinessCascaderBrandSeriesModels from '@/components/v2/business/BusinessCascaderBrandSeriesModels/index.vue'
import BusinessCascaderChannels from '@/components/v2/business/BusinessCascaderChannels/index.vue'
import BusinessCommonCascader from '@/components/v2/business/BusinessCommonCascader/index.vue'

import AddressSystem from '@/components/addressSystem.vue'
import SelectOptions from '@/components/SelectOptions.vue'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
export default {
  components: {
    CommonSelectTagsInput,
    CommonDatePicker,
    BusinessCascaderBrandSeriesModels,
    BusinessCascaderChannels,
    BusinessCommonCascader,
    AddressSystem,
    SelectOptions
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ({})
    },
    exhibition: {
      type: Boolean,
      default: false
    },
    inventory: {
      type: Boolean,
      default: false
    },
    orderShiftType: { // 转移订单类型
      type: Boolean,
      default: false
    },
    shopConfigData: { // 店铺数据
      type: Object,
      default: () => ({})
    },
    showOrderTimes: { // 展示订单级别数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {},
      minDate: null,
      maxDate: null,
      carInfoName: '',
      exhibitionCity: '', // 展会城市数据
      exhibitionCityInfo: [],
      outterColorList: [],
      innerColorList: [],
      allSeriesList: [], // 所有的车系
      seriesList: [], // panel展示的车系
      modelList: [],
      styleList: [], // 车款
      otherSeriesList: [], // 弹框展示的车系
      channelTwoList: [], // 渠道二级list
      channelThreeList: [], // 渠道三级list
      channelFourList: [], // 渠道四级list
      channelFiveList: [], // 渠道五级list
      dialogList: [],
      dialogShow: false,
      dialogOptions: [],
      allUserSourceList: [],// 所有的渠道来源数据
      otherUserSourceList: [],// 在弹框显示的渠道来源数据
      isMobile: false, // 判断是否是移动端
      areaShops: [], // 经销商门店的区域门店
      touchShops: [], // 触点店的数据
      flowDataTypeList: [], // 转移订单类型数据
      sameCityShops: [], // 获取同城门店
      orderLveleList: [], // 订单级别数据
      isOpenCustom: false,
      customField: {},
      customDateTime:'',
      visibleShow: false,
      addressObj: {},
      dateOption: [
        { value:1, key: 'day', text: this.$t('昨天') },
        { value:0, key: 'day', text: this.$t('今天') },
      ],
      parentCodeObj: { parentCode: '', value: '', selected: [], children: [], name: '' },
      childCodeObj: { childCode: '', value: [], selected: [], name: '' },
      dealerProvinceCodeObj: { dealerProvinceCode: '', value: '', selected: [], children: [], name: '' },
      dealerCityCodeObj: { dealerCityCode: '', value: '', selected: [], name: '' },
      dealerIdsObj: { dealerIds: '', value: [], selected: [], name: '' },
      childList: [], // 城市区域列表
      cityList: [], // 城市列表
    }
  },
  computed: {
    formatCityText() {
      const { citys } = this.formData
      return citys ? citys.map(({ name }) => name).join('/') : ''
    },
  },
  watch: {
    'formData.shop'(newVal,oldVal) {
      if (newVal?.value) {
      }
    },
    'formData.shopConfig'() {
    },
    options: {
      handler(newVal, oldVal) {
        console.info('options updated', newVal)
        const oldData = JSON.parse(JSON.stringify(this.formData))
        this.formData = {}
        for (let i in newVal) {
          if (newVal[i].multiple) {
            const nowVal = oldData[newVal[i].field]
            this.$set(this.formData, newVal[i].field, newVal[i].isCache ? (nowVal && nowVal.length ? nowVal : (newVal[i].defaultValue || [])):[])
            if ([ 'saIdList', 'visitStatus', 'latestDeliveryTask'].includes(newVal[i].field) && newVal[i].defaultValue) {
              this.$set(this.formData, newVal[i].field, newVal[i].defaultValue)
            }
            if (newVal[i].field === 'channelTwoId' && this.isMobile) {
              this.allUserSourceList = newVal[i].options
              newVal[i].options = this.allUserSourceList.slice(0,8)
              this.otherUserSourceList = this.allUserSourceList.slice(8)
            }
          } else if (newVal[i].type === 'dataRange') {
            this.$set(this.formData, newVal[i].start.field, newVal[i].start.value || '')
            this.$set(this.formData, newVal[i].end.field, newVal[i].end.value || '')
          } else if (newVal[i].type === 'inputNums') {
            newVal[i].options.forEach(item => {
              this.$set(this.formData, item.field, item.value || '')
            })
          } else {
            // this.$set(this.formData, newVal[i].field, this.formData[newVal[i].field] || '')
            // isCache 为 true 取缓存
            let val = oldData[newVal[i].field] || ''
            if ((newVal[i].defaultValue === 0 || newVal[i].defaultValue) && !newVal[i].isCache){
              val = newVal[i].defaultValue
            } else if (newVal[i].required && newVal[i].isCache && !val){
              val = newVal[i].defaultValue
            }
            this.$set(this.formData, newVal[i].field,val)
          }
          if (newVal[i].field === 'parentCode' && newVal[i].defaultValue) {
            const { options=[], defaultValue } = newVal[i]
            const obj = options.find(({ code }) => { return code === defaultValue }) || {}
            const { children=[], name } = obj
            const params = {
              children,
              parentCode: defaultValue,
              name,
              selected: defaultValue.split(','),
              value: defaultValue
            }
            this.onSelectOptionsChange(params, newVal[i])
          }
          if (newVal[i].field === 'childCode' && newVal[i].defaultValue) {
            const { defaultValue } = newVal[i]
            const params = {
              childCode: defaultValue,
              name: this.childList.find(({ code }) => { return code === defaultValue })?.name,
              selected: defaultValue.split(','),
              value: defaultValue.split(',')
            }
            this.onSelectOptionsChange(params, newVal[i])
          }
          if (newVal[i].field === 'dealerProvinceCode' && newVal[i].defaultValue) {
            const { options = [], defaultValue } = newVal[i]
            const obj = options.find(({ code }) => { return code === defaultValue }) || {}
            const { name } = obj
            const params = {
              dealerProvinceCode: defaultValue,
              name,
              selected: defaultValue.split(','),
              value: defaultValue
            }
            this.onSelectOptionsChange(params, newVal[i])
          }
          if (newVal[i].field === 'dealerCityCode' && newVal[i].defaultValue) {
            const { defaultValue } = newVal[i]
            const params = {
              dealerCityCode: defaultValue,
              name: this.cityList.find(({ code }) => { return code === defaultValue })?.name,
              selected: defaultValue.split(','),
              value: defaultValue
            }
            this.onSelectOptionsChange(params, newVal[i])
          }
          if (newVal[i].field === 'dealerIds' && newVal[i].defaultValue) {
            const { options=[], defaultValue } = newVal[i]
            const params = {
              dealerIds: defaultValue,
              name: options.find(({ code }) => { return code === defaultValue })?.name,
              selected: defaultValue.split(','),
              value: defaultValue.split(',')
            }
            this.onSelectOptionsChange(params, newVal[i])
          }
        }
      },
      immediate: true
    },
    visible(val){
      this.visibleShow = val
    }
  },
  mounted(){
    this.isMobile = /android|webos|iphone|ipad|blackberry/i.test(navigator.userAgent.toLowerCase())
  },
  methods: {
    selectDateOption(timeOption,fieldItem){
      switch (timeOption.key) {
        case 'day':
          this.$set(this.formData, fieldItem.start.field, dayjs().subtract(timeOption.value, 'day').hour(0).minute(0).second(0).toDate())
          this.$set(this.formData, fieldItem.end.field, dayjs().subtract(timeOption.value, 'day').hour(23).minute(59).second(59).toDate())
          break
        case 'hour': // n小时内
          this.$set(this.formData, fieldItem.start.field, dayjs().subtract(timeOption.value, 'hour').toDate())
          this.$set(this.formData, fieldItem.end.field, dayjs().toDate()) // 结束时间是当前时间
          break
        default:
          break
      }
      
    },
    handleInputNumsChange(index, options) {
      console.info('handleInputNumsChange', index, options)

      let firstValue = this.formData[options[0].field] || ''
      let secondValue = this.formData[options[1].field] || ''

      const firstMin = options[0].min || 0
      const secondMin = options[1].min || 0

      // 判断是否为数字，是则转为整数
      if (firstValue) {
        if (!isNaN(Number(firstValue))) {
          firstValue = Math.ceil(firstValue)
          this.formData[options[0].field] = firstValue
        }

        if (firstValue < firstMin) {
          this.$toast(`${this.$t('第一个值必须大于等于')}${firstMin}`)
          this.formData[options[0].field] = firstMin
          return
        }
      }

      if (secondValue) {
        if (!isNaN(Number(secondValue))) {
          secondValue = Math.ceil(secondValue)
          this.formData[options[1].field] = secondValue
        }

        if (secondValue < secondMin) {
          this.$toast(`${this.$t('第二个值必须大于等于')}${secondMin}`)
          this.formData[options[1].field] = secondMin
          return
        }
      }

      if (index === 0 && !secondValue) return

      // if (!firstValue) {
      //   if (index === 0) {
      //     this.formData[options[1].field] = ''
      //   } else {
      //     this.$toast('请先输入第一个值')
      //     this.formData[options[0].field] = firstMin ?? this.formData[options[1].field]
      //   }
      //   return
      // }

      if ((firstValue && secondValue) && (Number(secondValue) < Number(firstValue))) {
        this.$toast(this.$t('第二个值必须大于第一个值'))
        this.formData[options[1].field] = firstValue
        return
      }
    },
    onSelecteCity({ field }) {
      selectLocation({}, {
        columnsNum: 2
      }).then(city => {
        this.$set(this.formData, field, city.map(data => ({ ...data, value: data.code })))
      })
    },
    changeVisitStatus(label) {
      let data = {}
      if (label === this.$t('待回访')) {
        data = {
          id: 4,
          type: 'options',
          label: this.$t('待回访状态'),
          field: 'visitStatus',
          options: [
            { id: uuidv4(), label: this.$t('首次待回访'), value: 0 },
            { id: uuidv4(), label: this.$t('继续回访'), value: 1 },
          ],
        }
      }
      if (label === this.$t('已回访')) {
        data = {
          id: 4,
          type: 'options',
          label: this.$t('回访结果'),
          field: 'visitStatus',
          options: [
            // { id: uuidv4(), label: '待回访', value: 0 },
            { id: uuidv4(), label: this.$t('继续回访'), value: 1 },
            { id: uuidv4(), label: this.$t('战败'), value: 2 },
            { id: uuidv4(), label: this.$t('激活'), value: 3 },
          ],
        }
      }
      if (!label) {
        data = {
          id: 4,
          type: 'options',
          label: '',
          field: 'visitStatus',
          options: [],
        }
      }
      const visitStatusIndex = this.options.findIndex(item => item.field === 'visitStatus')
      visitStatusIndex !== -1 && (this.options[visitStatusIndex] = data)
    },
    onClose() {
      this.$emit('change', false)
      // this.submit()
    },
    /**
     * @function 时间范围
     */
    onSelectDate(params, type, compareField, dateType) {
      console.log(params, type, compareField, dateType)
      // 选择日期
      const { field } = params
      let range = {}

      // 如果 type 是 start 并且结果时间有值，则设置最大时间
      if (type === 'start' && this.formData[compareField]) {
        range['maxDate'] = this.formData[compareField]
      }

      // 如果 type 是 end 并且开始时间有值，则设置最小时间
      if (type === 'end' && this.formData[compareField]) {
        range['minDate'] = this.formData[compareField]
      }
      range.type = dateType || 'date'
      selectDate(range).then(res => {
        this.$set(this.formData, field, res)
        this.$emit('changeOptions', field, this.formData[field])
        console.log(this.formData,res)
      })
    },
    /**
     * 选择自定义时间范围
     */
    onSelectDateRange(field,item){
      const { isCustom,value,id } = item
      this.customField[field] = { ...item }
      if (!isCustom){
        this.$set(this.formData,field,{ id,value:{
          start: null,
          end: moment(new Date(new Date().getTime() - value*60*60*1000)).format('YYYY-MM-DD HH:mm:59')
        } })
        this.customDateTime=''
      } else {
        // 展示自定义时间选择器
        this.isOpenCustom = true
        this.$set(this.formData,field,{
          id
        })
      }
    },
    handleDateTimeConfirm({ dateTimes }){
      this.isOpenCustom=false
      const field = Object.keys(this.customField)[0]
      const { isCustom,value,id } = this.customField[field]
      this.$set(this.formData,field,{
        id,
        value:{
          start:dateTimes[0],
          end:dateTimes[1],
        }
      })
      this.customDateTime = `${dateTimes[0].split(' ')[0]}${this.$t(' 至 ')}${dateTimes[1].split(' ')[0]}`
    },
    // 选择品牌车型
    selectCarModel() {
      const shopId = this.formData.shop?.value
      if (typeof shopId === 'undefined') {
        this.$toast(this.$t('请先选择经销商门店'))
        return
      }
      selectCar({ shopId })
        .then(carInfo => {
          // if (typeof carInfo.brandCode === 'undefined') {
          //   this.$toast('请选择正确的品牌车系')
          //   return
          // }
          this.formData.carInfo = carInfo
          this.carInfoName = carInfo ? carInfo.brandName+(carInfo.seriesName || '')+(carInfo.modelName || '')+(carInfo.styleName || '') : ''
        })
        .catch(() => {})
    },
    // 获取颜色
    async getColorInfo() {
      if (!this.formData.carInfo){
        return false
      }
      if (!this.carInfoName) {
        return false
      }
      if (!this.formData.carInfo.styleCode) {
        return false
      }
      const outterColorList = await baseDataServices.getColor('tmOutterColorSales', this.formData.carInfo.styleCode)
      // 内饰暂不需要
      // const innerColorList = await baseDataServices.getColor('tmInnerColorSales', this.formData.carInfo.styleCode)
      this.outterColorList = outterColorList.map(item => ({
        id: item.id,
        label: item.colorName,
        value: item.colorCode
      }))
    },
    calculate(data=[], field) {
      if (data.length === 1) {
        const children = data[0].children || []
        this[field] = children.map((item) => ({ ...item, label: item.name, value: item.id }))
      } else {
        this[field] = []
      }
    },
    async onSelectedOption(field, option, multiple,scopeItem) {
      console.info('onSelectedOption', field, option, multiple)
      if (multiple) {
        const index = this.formData[field].findIndex(i => i.id === option.id)
        this.$forceUpdate()
        if (index > -1) {
          this.formData[field].splice(index, 1)
        } else {
          this.formData[field].push(option)
        }
      } else {
        if (this.formData[field] && this.formData[field].value === option.value) {
          const index = this.options.findIndex((item) => field === item.field && item.required)
          if (index === -1) {
            console.info('index', index, this.formData)
            this.$set(this.formData, field, null)
            this.$forceUpdate()
          }
        } else {
          this.$forceUpdate()
          this.$set(this.formData, field, option)
        }
      }
      // 选择了一个车系，显示车型列表 多个车系不显示车型列表
      if (option && field === 'seriesCodes' && option.modelList) {
        this.$set(this.formData, 'modelCodes', [])
        this.modelList = []
        if (multiple) { // 多选
          if (this.formData[field].length === 1) {
            this.modelList = this.formData[field][0].modelList.map(item => ({ id: item.code, label: item.name, value: item.code }))
          } else {
            this.modelList = []
          }
        } else {
          this.modelList = option.modelList.map(item => ({ id: item.code, label: item.name, value: item.code }))
        }
      }
      // 显示一级渠道来源
      const selectChannelData = this.formData[field]
      if (option && field === 'channelOneId'){ // 显示二级渠道
        this.$set(this.formData, 'channelTwoId', [])
        this.$set(this.formData, 'channelThreeId', [])
        this.$set(this.formData, 'channelFourId', [])
        this.$set(this.formData, 'channelFiveId', [])
        // 可以多选 同级渠道多选后 不展示下一级
        this.calculate(selectChannelData, 'channelTwoList')
        this.channelThreeList = []
        this.channelFourList = []
        this.channelFiveList = []
      } else if (option && field === 'channelTwoId') { // 显示三级级渠道来源
        this.$set(this.formData, 'channelThreeId', [])
        this.$set(this.formData, 'channelFourId', [])
        this.$set(this.formData, 'channelFiveId', [])
        this.calculate(selectChannelData, 'channelThreeList')
        this.channelFourList = []
        this.channelFiveList = []
      } else if (option && field === 'channelThreeId') { // 显示四级渠道来源
        this.$set(this.formData, 'channelFourId', [])
        this.$set(this.formData, 'channelFiveId', [])
        this.calculate(selectChannelData, 'channelFourList')
        this.channelFiveList = []
      } else if (option && field === 'channelFourId') { // 显示五级渠道
        this.$set(this.formData, 'channelFiveId', [])
        this.calculate(selectChannelData, 'channelFiveList')
      }
      if (['shopConfig'].includes(field)) {
        this.$emit('changeOptions', this.formData[field]?.value)
      }
      if (typeof scopeItem.onChange === 'function'){
        scopeItem.onChange(this.formData[field],this.formData)
      }
    },
    // 打开选择隐藏的option弹框
    selectOtherOption(optionItem){
      this.dialogShow = true
      const { field } = optionItem
      this.dialogOptions = [{ ...optionItem }]
      if (field === 'seriesCodes' || field === 'seriesCodeList'){
        this.dialogList = this.otherSeriesList
      } else if (field === 'channelTwoId') {
        this.dialogList = this.otherUserSourceList
      }
      // 判断是否是多选
      this.isArrayMark = this.options.filter(item => item.field === field)[0].multiple
    },
    handleBrandCarsCascaderChange(value, item) {
      console.info('handleBrandCarsCascaderChange', value, item)
      let { checkedOnlyArray = [], checkedObjOnlyArray = [], checkedObjectArray = [], checkedValues = [] } = value // 后端用or实现，所以这里只需要传给后端选中的最后一级
      const { fields = [], valueType = 'value' } = item
      this.$set(item, 'checkedObjectArray', checkedObjectArray)
      if (valueType == 'checkedValues') {
        this.$set(this.formData, item.field, checkedValues)
      } else {
        // 设置formData
        fields.forEach((keyName, index) => {
          const formDataValue = valueType === 'value' ? checkedOnlyArray[index] : checkedObjOnlyArray[index]
          this.$set(this.formData, keyName, formDataValue)  
        })

      }

      this.emitUpdate(item)
    },
    handleTagsInputDelete(tagValue, item, index) {
      console.info('handleTagsInputDelete', tagValue, item, index, { defaultValue: item.defaultValue?.length })

      const refName = `${item.type}_${item.field}`
      const refNode = this.$refs[refName][0]
      refNode.CHECK_OFF_ITEM(tagValue)
    },
    handleTagsInputSearch(value, item, index) {
      console.info('handleTagsInputSearch', value, item, index)
      const refName = `${item.type}_${item.field}`
      const refNode = this.$refs[refName][0]
      console.info('refNode', refNode)
      refNode.SEARCH(value)
    },
    handleChannelsCascaderChange(value, item) {
      console.info('handleChannelsCascaderChange', value, item)
      const { checkedOnlyArray = [], checkedObjOnlyArray = [], checkedObjectArray = [] } = value // 后端用or实现，所以这里只需要传给后端选中的最后一级
      const { fields = [], valueType = 'value', } = item
      this.$set(item, 'checkedObjectArray', checkedObjectArray)

      // 设置formData
      fields.forEach((keyName, index) => {
        const formDataValue = valueType === 'value' ? checkedOnlyArray[index] : checkedObjOnlyArray[index]
        this.$set(this.formData, keyName, formDataValue)  
      })
      this.emitUpdate(item)
    },
    emitUpdate(item) {
      // 更新
      if (!item || !item.onChange) return
      item.onChange(this.formData, item)
    },
    //
    onAddressChange(val,option){
      this.$set(this,'addressObj',val)
      this.$set(this.formData,option.field,val)
    },
    // 获取省市数据
    async getProvinceCity(parentId=0) {
      const res = await salesAssistantService.getPronceApi({ parentId })
      const result = res.map((item) => { return { ...item } })
      if (parentId === 0) {
        this.provinceList = []
        this.cityList = []
        this.provinceList = this.provinceList.concat(result)
      } else {
        this.cityList = []
        this.cityList = this.cityList.concat(result)
        const { value='' } = this.dealerCityCodeObj
        if (value) {
          const params = {
            dealerCityCode: value,
            name: this.cityList.find(({ code }) => { return code === value })?.name,
            selected: value.split(','),
            value: value
          }
          this.$set(this, 'dealerCityCodeObj', params)
        }
      }
    },
    // 获取销售门店数据
    getDealers: debounce (async function(query){
      const obj = this.options.find(item => item.type === 'selectOptions' && item.field === 'dealerIds')
      const params = {
        pageNum: 1,
        pageSize: 100000,
        ...query
      }
      if (obj && obj.dealerType) params.dealerType = obj.dealerType
      const res = await salesAssistantService.getRegionPageApi(params)
      const { dataList=[] } = res
      const result = dataList.map((item) => { return { code: item.id, name: item.name } })
      obj.options = result
      const { value=[] } = this.dealerIdsObj
      if (value && value.length > 0) {
        const params = {
          dealerIds: value,
          name: result.filter(({ code }) => { return value.includes(code) })?.map(({ name }) => { return name }).join(','),
          selected: value,
          value: value
        }
        this.$set(this, 'dealerIdsObj', params)
      }
    }, 500),
    async onSelectOptionsChange(val, option) {
      // 是否重复选择
      const repeat = this[`${option.field}Obj`].selected?.join(',') === val.selected?.join(',')
      this.$set(this.formData, option.field, val.selected.length > 0 ? val : null)
      this.$set(this, [`${option.field}Obj`], val)
      if (option.field === 'parentCode') {
        if (!repeat) {
          this.$set(this, 'childCodeObj', { childCode: '', value: [], selected: [], name: '' })
          this.$set(this, 'dealerIdsObj', { dealerIds: '', value: [], selected: [], name: '' })
          this.$set(this, 'childList', val.children||[])
          this.$set(this.formData, 'childCode', null)
          this.$set(this.formData, 'dealerIds', null)
        }
      } else if (option.field === 'childCode') {
        if (!repeat) {
          this.$set(this, 'dealerIdsObj', { dealerIds: '', value: [], selected: [], name: '' })
          this.$set(this.formData, 'dealerIds', null)
        }
      } else if (option.field === 'dealerProvinceCode') {
        if (!repeat) {
          this.$set(this, 'dealerCityCodeObj', { dealerCityCode: '', value: '', selected: [], name: '' })
          this.$set(this, 'dealerIdsObj', { dealerIds: '', value: [], selected: [], name: '' })
          this.$set(this.formData, 'dealerCityCode', null)
          this.$set(this.formData, 'dealerIds', null)
        }
        await this.getProvinceCity(val.dealerProvinceCode || 0)
      } else if (option.field === 'dealerCityCode') {
        if (!repeat) {
          this.$set(this, 'dealerIdsObj', { dealerIds: '', value: [], selected: [], name: '' })
          this.$set(this.formData, 'dealerIds', null)
        }
      } else if (option.field === 'dealerIds') {
      }
      this.$forceUpdate()
      if (option.field !== 'dealerIds') {
        const params = {
          parentCode: this.parentCodeObj.parentCode,
          childCode: this.childCodeObj.selected,
          provinceCode: this.dealerProvinceCodeObj.dealerProvinceCode,
          cityCode: this.dealerCityCodeObj.dealerCityCode,
        }
        this.getDealers(params)
      }
    },
    reset() {
      this.$emit('beforeReset',this.options)
      console.log('rese')
      this.seriesList = []
      this.modelList = []
      this.styleList = []
      this.channelThreeList = []
      this.channelFourList = []
      this.outterColorList = []
      this.exhibitionCity = ''
      this.exhibitionCityInfo = []
      this.customField = {}
      this.customDateTime = ''
      this.$set(this,'addressObj', {})
      this.$set(this,'parentCodeObj', { parentCode: '', value: '', selected: [], children: [], name: '' })
      this.$set(this,'childCodeObj', { childCode: '', value: [], selected: [], name: '' })
      this.$set(this,'dealerProvinceCodeObj', { dealerProvinceCode: '', value: '', selected: [], name: '' })
      this.$set(this,'dealerCityCodeObj', { dealerCityCode: '', value: '', selected: [], name: '' })
      this.$set(this,'dealerIdsObj', { dealerIds: '', value: [], selected: [], name: '' })
      this.childList = [] // 城市区域列表
      this.cityList = [] // 城市列表
      const params = {
        parentCode: this.parentCodeObj.parentCode,
        childCode: this.childCodeObj.selected,
        provinceCode: this.dealerProvinceCodeObj.dealerProvinceCode,
        cityCode: this.dealerCityCodeObj.dealerCityCode,
      }
      this.getDealers(params)
      for (let i in this.options) {
        const item = this.options[i]
        if (item.required) {
          if (item.isCache && (item.defaultValue || item.defaultValue === 0)){
            this.$set(this.formData, item.field, item.defaultValue)
          }
          continue
        }
        if (item.type === 'dataRange') {
          this.formData[item.start.field] = item.start.value || null
          this.formData[item.end.field] = item.end.value || null
        } else if (item.type === 'inputNums') {
          item.options.forEach(j => {
            this.formData[j.field] = null
          })
        } else if (item.type === 'channels') {
          item.defaultValue = []
        } else if (item.type === 'cars' || item.type == 'commonCascader') {
          try {
            console.log(item.defaultValue, 'item.defaultValue')
            const ref=`${item.type}_${item.field}`
            item.defaultValue = []
            this.$refs[ref][0].reset()
          } catch (error) {}
        } else {
          if (this.options[i].multiple) {
            this.$set(this.formData, this.options[i].field,item.isCache && item.defaultValue || [])
          } else {
            this.$set(this.formData, this.options[i].field, null)
          }
        }
      }
      this.formData.outterColor = null
      this.formData.carInfo = null
      this.carInfoName = this.$options.data.carInfoName
      this.$emit('reset')
    },
    submit() {
      this.$emit('submit', this.formData)
    },
  },
}
</script>

<style scoped lang="less">
.filter-panel {
  background-color: #fff;
  padding: 10px 0 50px 0;
  position: relative;
  height: 100%;
  .content {
    width: 100%;
    height: calc(100% - 46px - 10px);
    padding: 0 15px;
    overflow-y: auto;
    // display: flex;
    // flex-wrap: wrap;
    .item {
      width: 100%;
      &.selectOptions_item{
        width: 50%;
        display: inline-block;
      }
      &.selectOptions_item:nth-child(2){
        margin-top: 0px;
      }
      & + .item {
        margin-top: 16px;
      }
      .label {
        display: flex;
        align-content: center;
        justify-content: space-between;
        color: #646566;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        padding: 12px 0;
        .time-options{
          flex: 1;
          .option{
            margin-left: 12px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
      .specialLabel{
        display: flex;
        align-self: start;
        justify-content: space-between;
      }
      .date-range {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-select {
          width: 48%;
          padding: 12px 5px;
          background-color: #F2F2F2;
          font-size: 14px;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          .value {
            /*width: 89px;*/
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 14px;
          }
          .placeholder {
            color: #B2B2B2;
          }
          .icon {
            width: 8px;
            margin-left: 5px;
          }
        }
      }
      .options {
        margin-top: 12px;
        .option {
          padding: 0 12px;
          background-color: #E7E7E7;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          display: inline-block;
          text-align: left;
          line-height: 36px;
          margin-right: 2%;
          margin-bottom: 12px;
          border-radius: 3px;
          &.active {
            background-color: @yellow;
          }
        }
      }

      .input-nums {
        display: flex;
        align-items: center;
        margin-right: 2%;

        .input-box {
          flex: 1;
          background-color: #F2F2F2;

          input {
            width: 100%;
            background-color: #F2F2F2;
            border: 0;
            font-size: 14px;
            padding: 0 12px;
            line-height: 36px;
          }
        }

        .separator {
          padding: 0 10px;
          opacity: 0.5;
        }
      }
    }
  }
  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    left: 0;
    padding: 0 15px;
    .btn-common {
      & + .btn-common {
        margin-left: 15px;
      }
    }
  }
}
</style>
